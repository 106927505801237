import React from 'react';
import classNames from 'classnames';
import Header from '../../platformui/Header';
import HeaderTools from '../header-tools';
import Regions from './regions';
import RegionLogo from './region-logo';
import Logo from '../logo';
import RegionsContainer from './regions-container';
import ReadingAidPanel from '../../platformui/ReadingAid/reading-aid-panel';
import HeaderContainer from './header-container';
import Spinner from '../../platformui/Spinner';
import GlobalSearch from '../globalsearch';
import {useSweLang} from '../../platformui/Helpers'

const Header1177 = (props) => {
  return (
    <div
      className={classNames({
        header: true,
        'header--has-region': props.regionLogo || props.currentRegion,
        'header--search-is-open': props.activeTool === 'search',
      })}
      lang = {useSweLang(props.Language)}
    >
      <GlobalSearch isMobile={true} {...props} id="globalsearch--mobile" />
      <Regions {...props} className="regions-outer-wrapper" />
      <ReadingAidPanel
        {...props}
        isVisible={props.activeTool === 'reading-aid'}
        Spinner={Spinner}
      />

      <Header
        {...props}
        Logo={Logo}
        HasSearch={true}
        topMenuSearch={true}
        activeTool={props.activeTool}
        chevronMobile="chevron--mobile"
        Tools={RegionLogo}
        ToolsBottom={HeaderTools}
        isWideNav={true}
        hideHamburgerMenuInHeader={true}
      />
    </div>
  );
};

export default HeaderContainer(RegionsContainer(Header1177));
