import React from 'react';

export default () => (
  <svg
    width="121"
    height="60"
    xmlns="http://www.w3.org/2000/svg"
    className="region-logo__img region-logo__img--is-visible"
    preserveAspectRatio="xMinYMid meet"
    viewBox="0 0 60 34"
  >
    <defs>
      <path id="a1" d="M0 24.384h67.884V.046H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FEFEFE"
        d="M32.449 18.93h-2.113v-1.112h2.003v-1.426h-2.003v-1.081h2.113v-1.426h-3.884v6.472h3.884z"
      />
      <g transform="translate(0 4.126)">
        <path
          fill="#FEFEFE"
          d="M36.734 16.45c1.139 0 2.146-.386 2.812-1.274.61-.804.682-1.614.698-2.56h-3.521v1.322h1.447c-.072.747-.66 1.128-1.425 1.128-1.172 0-1.804-1.023-1.804-2.026 0-.988.605-2.048 1.776-2.048.71 0 1.227.413 1.475 1.034l1.683-.674c-.588-1.207-1.678-1.812-3.075-1.812-2.2 0-3.703 1.384-3.703 3.489 0 2.037 1.491 3.422 3.637 3.422"
        />
        <mask id="b1" fill="#fff">
          <use xlinkHref="#a1" />
        </mask>
        <path
          fill="#FEFEFE"
          d="M40.992 16.231h1.777V9.76h-1.777zM47.29 14.816a1.9 1.9 0 0 1-1.925-1.933c0-.893.865-1.708 1.926-1.708 1.056 0 1.925.815 1.925 1.708 0 1.117-.87 1.933-1.925 1.933m3.771-1.939c0-1.959-1.808-3.337-3.771-3.337-1.965 0-3.775 1.378-3.775 3.337 0 2.095 1.585 3.574 3.775 3.574 2.189 0 3.771-1.479 3.771-3.574M53.634 12.267h.018l3.25 3.964h1.773V9.76h-1.773v3.96h-.02l-3.248-3.96H51.86v6.472h1.774zM24.62 22.958h-.397v-3.625h.414c1.14 0 1.946.653 1.946 1.812 0 1.263-.902 1.813-1.964 1.813m.346-5.052h-2.518v6.478h2.518c1.888 0 3.461-1.42 3.461-3.239 0-1.823-1.562-3.239-3.461-3.239M30.975 21.97l.732-2.063h.016l.726 2.064h-1.474zm-.22-4.064l-2.625 6.478h1.882l.473-1.128h2.443l.44 1.128h1.893l-2.57-6.478h-1.936zM37.502 17.906h-1.776v6.478h3.9v-1.426h-2.124zM42.812 21.97l.732-2.063h.016l.727 2.064h-1.475zm-.22-4.064l-2.62 6.478h1.883l.468-1.128h2.442l.446 1.128h1.887l-2.569-6.478h-1.936zM49.401 20.81h-.17v-1.613h.17c.581 0 1.232.1 1.232.804 0 .705-.65.81-1.232.81m3.076-.914c0-1.38-1.021-1.99-2.367-1.99h-2.652v6.477h1.772v-2.492h.017l1.676 2.492h2.207l-2.145-2.654c.968-.167 1.492-.94 1.492-1.833M58.601 21.866h-.013l-3.25-3.96h-1.772v6.478h1.771v-3.97h.02l3.244 3.97h1.777v-6.478H58.6zM63.593 21.97l.731-2.063h.022l.722 2.064h-1.475zm1.716-4.064h-1.936l-2.62 6.478h1.882l.472-1.128h2.444l.44 1.128h1.893l-2.575-6.478zM23.718 6.695l-2.427-4.342V.046l-2.165 1.187a7.897 7.897 0 0 0-1.357-.12c-2.813 0-5.261 1.502-6.504 3.703L9.988 7.15l-6.008.219C1.79 7.48.045 9.175.004 11.271L0 24.374h2.504c0-4.909 1.837-7.404 1.837-7.404a42.213 42.213 0 0 0 9.712-.046l.95 7.45h2.504c0-12.722 2.592-16.68 2.592-16.68l2.358.533a4.583 4.583 0 0 0 1.261-1.532"
          mask="url(#b1)"
        />
        <path
          fill="#FEFEFE"
          d="M24.223 11.044h.17c.579 0 1.233.105 1.233.81 0 .705-.654.804-1.232.804h-.17v-1.614zm0 2.7h.017l1.672 2.487h2.208l-2.142-2.654c.968-.161 1.49-.935 1.49-1.828 0-1.384-1.022-1.99-2.37-1.99h-2.65v6.472h1.775v-2.486z"
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
);
