import React from 'react';
import GlobalSearch from '../globalsearch';
import Burger from '../../platformui/Header/burger';
import classNames from 'classnames';

const HeaderTools = (props) => {

    const handleClick = () => {
        if (typeof _paq !== 'undefined') {
            window._paq.push(['trackEvent', 'Vardpersonal', 'Click Vardpersonal 1177', 'Click Vardpersonal 1177 Link'])
        }
    }

  return (
    <div>
      <div className="header-tools">
              <ul className="header-tools__list">
                  {props.CareGiverItemSettings.TopMenuItemEnabled && (
                      <li className="header-tools__list__item header-tools__list__item--care-giver ">
                          <a className="header-tools__list__item__link"
                              href={props.CareGiverItemSettings.TopMenuItemURL}
                              onClick={handleClick}
                          >
                              <i className="care-giver-icon" />
                              <span>{props.CareGiverItemSettings.TopMenuItemText}</span>
                              <span className="show-for-sr">
                                  {props.Content.Translations.quicklinkssr}
                              </span>
                          </a>
                      </li>
                  )}
          {props.EserviceItemSettings.TopMenuItemEnabled && (
            <li className="header-tools__list__item header-tools__list__item--auth">
              <a
                className="header-tools__list__item__link"
                href={props.EserviceItemSettings.TopMenuItemURL}
              >
                <i className="auth-icon" />
                <span>{props.EserviceItemSettings.TopMenuItemText}</span>
                <span className="show-for-sr">
                  {props.Content.Translations.quicklinkssr}
                </span>
              </a>
            </li>
          )}
          {props.ReadingAid.EnableReadingAid && (
            <li className="header-tools__list__item header-tools__list__item--listen">
              <button
                type="button"
                className="header-tools__list__item__button"
                onClick={() => {
                  props.toggle('reading-aid');
                }}
                aria-controls="reading-aid"
                id="reading-aid-panel-btn"
                aria-expanded={props.activeTool === 'reading-aid'}
              >
                <i
                  className={
                    props.activeTool === 'reading-aid'
                      ? 'listen-icon listen-icon--close'
                      : 'listen-icon listen-icon--open'
                  }
                />
                <span>{props.Content.Translations.listen}</span>
                <span className="show-for-sr">
                  {props.Content.Translations.quicklinkssr}
                </span>
              </button>
            </li>
          )}
          {props.FindCareItemSettings.TopMenuItemEnabled && (
            <li className="header-tools__list__item header-tools__list__item--find-care">
              <a
                className="header-tools__list__item__link"
                href={props.FindCareItemSettings.TopMenuItemURL}
              >
                <i className="find-care-icon" />
                <span>{props.FindCareItemSettings.TopMenuItemText}</span>
                <span className="show-for-sr">
                  {props.Content.Translations.quicklinkssr}
                </span>
              </a>
            </li>
          )}

          <li
            className={classNames({
              'header-tools__list__item header-tools__list__item--search': true,
              'header-tools__list__item--is-expanded':
                props.activeTool === 'search',
            })}
          >
            <button
              type="button"
              aria-controls="panel-globalsearch"
              className="header-tools__list__item__button header-tools__list__item__button--search-desktop"
              data-cy="header-tools-search-btn"
              onClick={() => {
                props.toggle('search');
              }}
              aria-expanded={props.activeTool === 'search'}
            >
              <i
                className={
                  props.activeTool === 'search'
                    ? 'search-icon search-icon--close'
                    : 'search-icon search-icon--open'
                }
              />
              <span>{props.Content.Translations.search}</span>
              <span className="show-for-sr">
                {props.Content.Translations.quicklinkssr}
              </span>
            </button>
          </li>

          <li className="header-tools__list__item header-tools__list__item--burger">
            <Burger
              {...props}
              showHeader={true}
              handleHamburgerClick={props.handleHamburgerClick}
              burgerBtnClassname="header-tools__burger"
            />
          </li>
        </ul>
      </div>

      <GlobalSearch isMobile={false} {...props} id="globalsearch" />
    </div>
  );
};

export default HeaderTools;
