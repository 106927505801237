import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as serviceWorker from './platformui//Base/ServiceWorker/serviceWorker';
import * as Render from './platformui//Base/Render';
import Layout from './framework/layouts/layout';

import AsyncPageTypes from './async';
import RegionLogotypes from './components/header/region-logotypes';
import BuildInfo from './platformui//BuildInfo';

import './framework/styles/app.scss';

// We import() just to create the chunks, but must not actually try to load the chunks here, so we
// wrap the import() in a lazy and then just never use it.
React.lazy(() => import(/* webpackChunkName: "preload" */'./preload.js'));
React.lazy(() => import(/* webpackChunkName: "editor" */'./editor.js'));

const Preview = React.lazy(() => import(/* webpackChunkName: "preview" */'./framework/layouts/preview'));

// Additional requires that are not implicity loaded into the dependency graph. May require custom loading in webpack.config.js.
require.resolve('./1177-manifest.json');
require.context('./framework/favicons/', true, /\.png$/);

const main = () => {
  const StaticRegionImgSrc = Render.staticComponent(RegionLogotypes, 'regionlogo');
  const StaticBuildInfo = Render.staticComponent(BuildInfo, 'b-info');

  let appNode = document.getElementById('app')?.firstChild;
  let previewNode = document.getElementById('preview')?.firstChild;

  let hydrateOrRender = appNode?.firstChild ? hydrate : render; // if <div id='app'><div></div></div>, SSR is disabled and we render instead of hydrate.
  appNode && hydrateOrRender(
    <Router>
      <Route
        path="*"
        render={() => (
          <Layout
            cookies={document.cookie}
            BuildInfoComponent={StaticBuildInfo}
            PageTypeRenderer={AsyncPageTypes}
            LoadBlocks={(callback) => {
              import('./blocks/bundle-meganav-blocks').then((blocks) => {
                callback(blocks);
              });
            }}
            CurrentRegionLogo={StaticRegionImgSrc}
          />
        )}
      />
    </Router>,
    appNode
  );

  previewNode && render(
      <Router>
        <Suspense fallback={<div>Laddar...</div>}>
          <Route path="*" render={() => <Preview />} />
        </Suspense>
      </Router>,
      previewNode
    );
};

serviceWorker.register();
main();