const isPost = (props) => {
  return props.Content && props.Content.IsPost;
};

const isSearchBot = (props) => {
  return props.IsSearchBot;
};

const isDefaultRegion = (props) => {
  return (
    props.Regionalization.Regions &&
    (props.Regionalization.Regions.DefaultOption.Selected ||
      props.PageTypeName === 'ErrorPage')
  );
};

export const hasCurrentRegion = (props) => {
  return (
    props.Regionalization.Regions &&
    props.Regionalization.Regions.Options.find((item) => item.Selected === true)
  );
};

export const isExpanded = (props) =>
  isSearchBot(props)
    ? false
    : !isPost(props) && !isDefaultRegion(props) && !hasCurrentRegion(props);
