import React from 'react';
import Skane from './logotypes/skane';
import Blekinge from './logotypes/blekinge';
import Dalarna from './logotypes/dalarna';
import Gotland from './logotypes/gotland';
import Gavleborg from './logotypes/gavleborg';
import Halland from './logotypes/halland';
import Jamtland from './logotypes/jamtland';
import Jonkoping from './logotypes/jonkoping';
import Kalmar from './logotypes/kalmar';
import Kronoberg from './logotypes/kronoberg';
import Norrbotten from './logotypes/norrbotten';
import Stockholm from './logotypes/stockholm';
import Sormland from './logotypes/sormland';
import Uppsala from './logotypes/uppsala';
import Varmland from './logotypes/varmland';
import Vasterbotten from './logotypes/vasterbotten';
import Vasternorrland from './logotypes/vasternorrland';
import Vastmanland from './logotypes/vastmanland';
import Vastragotaland from './logotypes/vastragotaland';
import Orebro from './logotypes/orebro';
import Ostergotland from './logotypes/ostergotland';

const RegionLogotypes = (props) => {
  if (!props.currentRegion) return null;

  switch (props.currentRegion.Value) {
    case '14':
      return <Vastragotaland />;
    case '01':
      return <Stockholm />;
    case '19':
      return <Vastmanland />;
    case '03':
      return <Uppsala />;
    case '12':
      return <Skane />;
    case '18':
      return <Orebro />;
    case '23':
      return <Jamtland />;
    case '24':
      return <Vasterbotten />;
    case '22':
      return <Vasternorrland />;
    case '17':
      return <Varmland />;
    case '04':
      return <Sormland />;
    case '05':
      return <Ostergotland />;
    case '25':
      return <Norrbotten />;
    case '21':
      return <Gavleborg />;
    case '07':
      return <Kronoberg />;
    case '08':
      return <Kalmar />;
    case '06':
      return <Jonkoping />;
    case '13':
      return <Halland />;
    case '09':
      return <Gotland />;
    case '10':
      return <Blekinge />;
    case '20':
      return <Dalarna />;
    default:
      return null;
  }
};

export default RegionLogotypes;
