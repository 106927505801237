import React from 'react';
import Footer from '../../platformui/Footer';
import { isMediumViewport } from '../../platformui/Hooks/useMedia';

import footerSvg from '../../framework/images/1177-logo.svg';

const Logo = () => (
  <img suppressHydrationWarning={true} src={footerSvg} alt="" />
);

export default (props) => (
  <Footer
    key="footer"
    {...props}
    siteFooterLinkListModifiers={{
      'c-linklist--no-icons': true,
    }}
    ShowLogo={isMediumViewport()}
    FooterLogo={Logo}
    Modifiers={{ 'footer-linklist': true }}
  />
);
