import React from 'react';
import AccessibilityNav from '../../platformui/AccessibilityNav';

const AccessibilityShortcuts = (props) => (
  <AccessibilityNav>
    <li>
      <a href="#content">Till sidans huvudinnehåll</a>
    </li>
    {props.PageTypeName === 'SearchPage' && (
      <li>
        <a href="#search">Till sökresultatet</a>
      </li>
    )}
  </AccessibilityNav>
);

export default AccessibilityShortcuts;
