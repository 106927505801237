import { string } from 'prop-types';
import { useEffect } from 'react';

const MatomoTrackDimensions = ({Region}) => {
  useEffect(() => {
    const sendEventData = (isAppUser, region) => {
      if (window?._mtm) {
        window?._mtm.push({'event': 'mtm.PageView', 'regionValue': region === undefined ? 'Nationellt' : region});
        window?._mtm.push({'event': 'mtm.PageView', 'appUser': isAppUser});
      }
    };

    if (
      typeof window !== 'undefined' &&
      window?.navigator?.userAgent.includes('1177-appen')
    ) {
      sendEventData('true', Region);
    } else if (typeof window !== 'undefined') {
      sendEventData('false', Region);
    }
  }, []);
  return null;
};

MatomoTrackDimensions.propTypes = {
  Region: string,
};

export default MatomoTrackDimensions;
