import React, { useRef, useEffect } from 'react';

export default (WrappedComponent) => {
  const TABBABLE_ELEMENTS = 'a[href], button';
  const KEYCODE_ESC = 27;
  const KEYCODE_TAB = 9;

  const Container = (props) => {
    const keepTab = useRef();

    const setUpEventListener = () => {
      //First remove eventlistener

      //Listen for esc key
      const keyListener = (event) => {
        const keyCode = event.which || event.keyCode; // Get the current keycode

        if (keyCode === KEYCODE_ESC) props.closeHandler && props.closeHandler();
      };

      keepFocus();

      document.addEventListener('keydown', keyListener, false);
    };

    useEffect(() => {
      setUpEventListener();
    }, []);

    const keepFocus = () => {
      const context = keepTab.current;
      const allTabbableElements = context.querySelectorAll(TABBABLE_ELEMENTS);
      const firstTabbableElement = allTabbableElements[0];
      const lastTabbableElement =
        allTabbableElements[allTabbableElements.length - 1];

      const keyListener = (event) => {
        var keyCode = event.which || event.keyCode; // Get the current keycode
        // Polyfill to prevent the default behavior of events
        event.preventDefault =
          event.preventDefault ||
          function () {
            event.returnValue = false;
          };

        // If it is TAB
        if (keyCode === KEYCODE_TAB) {
          // Move focus to first element that can be tabbed if Shift isn't used
          if (event.target === lastTabbableElement && !event.shiftKey) {
            event.preventDefault();
            firstTabbableElement.focus();

            // Move focus to last element that can be tabbed if Shift is used
          } else if (event.target === firstTabbableElement && event.shiftKey) {
            event.preventDefault();
            lastTabbableElement.focus();
          }
        }
      };
      context.addEventListener('keydown', keyListener, false);
    };

    const { className } = props;
    return (
      <div ref={keepTab} className={className}>
        <WrappedComponent {...props} />
      </div>
    );
  };
  return Container;
};
