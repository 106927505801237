import React from 'react';
import classNames from 'classnames';
import SearchField from '../../platformui/Search/searchfield';

const GlobalSearch = (props) => {
  const globalSearchClasses = classNames({
    'c-globalsearch': true,
    'c-globalsearch--is-opened': props.activeTool === 'search',
    'c-globalsearch--is-mobile': props.isMobile,
    'c-globalsearch--is-desktop': !props.isMobile,
  });

  const { SearchSettings } = props;

  return (
    <div
      className={globalSearchClasses}
      id={`panel-${props.id}`}
      data-cy="globalsearch-panel"
      aria-hidden={props.activeTool !== 'search'}
    >
      <div className="c-globalsearch__inner" role="search">
        <form action={SearchSettings.SearchUrl} method="get" autoComplete="off">
          <div className="c-globalsearch__form-component">
            <label
              className="c-globalsearch__field__label icon-search"
              htmlFor={props.id}
            >
              <span className="show-for-sr">
                {SearchSettings.SearchPlaceholder &&
                  SearchSettings.SearchPlaceholder}
              </span>
            </label>

            <SearchField
              id={props.id}
              className="c-globalsearch"
              searchUrl={SearchSettings.SearchUrl}
              placeholder={SearchSettings.SearchPlaceholder}
            />

            <button type="submit" className="c-globalsearch__field__button">
              Sök
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GlobalSearch;
