import React, { useState, useEffect } from 'react';
import { hasCurrentRegion } from './helpers';

export default (WrapperComponent) => {
  const Container = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const currentRegion = hasCurrentRegion(props);
    const region = null;
    const regionLogo = null;
    const getRegions = null;

    let focusTimer = null;

    useEffect(() => {
      if (props.activeTool === 'region-picker') {
        setIsVisible(!isVisible);
        focusElm('region-wrapper-focus');
      }

      if (props.activeTool !== 'region-picker') {
        setIsVisible(false);
      }
    }, [props.activeTool]);

    const focusElm = (id) => {
      clearTimeout(focusTimer);
      //document.getElementById(id).blur();
      focusTimer = setTimeout(() => {
        //Wait 350ms for animation to finish
        document.getElementById(id) && document.getElementById(id).focus();
      }, 450);
    };

    return (
      <WrapperComponent
        {...props}
        region={region}
        getRegions={getRegions}
        currentRegion={currentRegion}
        regionLogo={regionLogo}
        isVisible={isVisible}
      />
    );
  };
  return Container;
};
