import React from 'react';

const Logo = (props) => {
  return (
    <div id="site-logo">
      <div className="logo">
        <div className="logo__inner">
          <a href={props.StartPageLink} className="logo__link">
            <span className="show-for-sr" data-cy="ui-header-logosr">{props.logosr}</span>

            <svg
              width="102"
              height="40"
              className="logo__svg"
              viewBox="0 0 102 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.4878 39.9429C7.93568 39.9429 5.86203 37.8512 5.86203 35.2769H5.90191V10.1366H5.06448C2.27304 10.1366 0 7.884 0 5.06828C0 2.29279 2.23316 0 5.0246 0H10.4878C13.04 0 15.1137 2.09167 15.1137 4.66604V35.2769C15.1137 37.8512 13.04 39.9429 10.4878 39.9429Z" />
              <path d="M27.5553 35.2769V10.1366H26.7578C23.9663 10.1366 21.6933 7.884 21.6933 5.06828C21.6933 2.29279 23.9264 0 26.7179 0H32.1811C34.7333 0 36.807 2.09167 36.807 4.66604V35.2769C36.807 37.8512 34.7333 39.9429 32.1811 39.9429C29.629 39.9429 27.5553 37.8512 27.5553 35.2769Z" />
              <path d="M79.0382 10.1768H86.4554L90.523 0H79.0382C76.2467 0 74.0136 2.29279 74.0136 5.06828C73.9737 7.884 76.2467 10.1768 79.0382 10.1768Z" />
              <path d="M92.796 2.97691C93.7531 0.563443 96.4249 -0.603068 98.8175 0.36232C101.17 1.28748 102.327 4.02275 101.41 6.43622L89.207 37.0471C88.2499 39.4605 85.5781 40.6271 83.1855 39.6617C80.7928 38.6963 79.6363 36.0012 80.5934 33.5878L92.796 2.97691Z" />
              <path d="M47.1754 10.1768H54.5927L58.6602 0H47.1754C44.384 0 42.1508 2.29279 42.1508 5.06828C42.1508 7.884 44.4239 10.1768 47.1754 10.1768Z" />
              <path d="M60.9333 2.97691C61.8904 0.563443 64.5622 -0.603068 66.9549 0.36232C69.3475 1.28748 70.504 4.02275 69.5469 6.43622L57.3443 37.0471C56.3873 39.4605 53.7155 40.6271 51.3228 39.6617C48.9301 38.6963 47.7737 36.0012 48.7307 33.5878L60.9333 2.97691Z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Logo;
