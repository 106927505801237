import React from 'react';
import { importPage } from './pages/importPage';

export default class Async extends React.Component {
  state = {
    Pages: null,
    html: document.getElementById('async-pagetypes')
      ? document.getElementById('async-pagetypes').innerHTML
      : null,
  };

  async componentDidMount() {
    const pagetype = await importPage(this.props.PageTypeName);
    this.setState({ Pages: pagetype });
  }

  render() {
    if (!this.state.Pages)
      return (
        <div
          id="async-pagetypes"
          dangerouslySetInnerHTML={{ __html: this.state.html }}
        ></div>
      );

    return (
      <div id="async-pagetypes">
        <this.state.Pages {...this.props} />
      </div>
    );
  }
}
